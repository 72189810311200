

















































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Metabase } from '@/components/metabase'
import { DropDownController, CourseController, TeacherController } from '@/services/request.service'
import { getSchoolInfo, guid, judgeVnode } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import FlexTooltip from '@/components/FlexTooltip.vue'
import moment from 'moment'
@Component({
  components: {
    Metabase,
    filterGroup,
    FlexTooltip,
  },
})
export default class ReportViewer extends Vue {
  private grades: Array<any> = []
  private colleges: Array<any> = []
  private classes: any = []
  private ccaClasses: any = []
  private courses: any = []
  private teachers: Array<any> = []
  private filterCondition: any = {
    show: false,
  }
  private floorList: Array<any> = []
  private studyRoomList: Array<any> = []
  private schoolYearList: Array<any> = []
  private subjectList: Array<any> = []
  private sectionIds: any = []
  private env = process.env.VUE_APP_ENV

  private get timeTypes(): any {
    return [
      {
        key: 'all',
        label: this.$t('common.all'),
      },
      {
        key: 8,
        label: this.$t('common.am'),
      },
      {
        key: 9,
        label: this.$t('common.pm'),
      },
      {
        key: 10,
        label: 'Day',
      },
    ]
  }
  private get period(): any {
    return [
      {
        key: 'am',
        label: 'AM',
      },
      {
        key: 'pm',
        label: 'PM',
      },
      {
        key: 'eve',
        label: 'EVE',
      },
    ]
  }
  private get attendanceTypes(): any {
    return [
      {
        key: 'all',
        label: this.$t('common.all'),
      },
      {
        key: 'Session',
        label: this.$t('attendanceReport.semesterAttendance'),
      },
      {
        key: 'Class',
        label: this.$t('attendanceReport.classAttendance'),
      },
    ]
  }

  private get classesOfGrade(): any {
    // if(this.reportData.params.sectionId === 'all' || !this.reportData.params){
    //   return []
    // }
    // return (this.classes.filter(item => item.key === this.reportData.params.sectionId)[0] || []).subOptions
    return this.classes.map(item => item.subOptions).flat(1)
  }

  private get formatSections(): any {
    return this.grades.map(item => {
      return {
        children: item.subOptions.map(child => ({
          key: child.key,
          title: this.locale === 'zh' ? child.value : child.enValue || child.value,
          value: child.key,
        })),
        title: `${item.value}-${item.enValue}`,
        key: `${item.enValue}_${item.extraValue}_${item.key}`,
        value: `${item.enValue}_${item.extraValue}_${item.key}`,
      }
    })
  }

  private get attendanceStatus(): any {
    return [
      {
        key: 'all',
        label: this.$t('common.all'),
      },
      {
        key: 1,
        label: this.$t('attendance.statusList.intime'),
      },
      {
        key: 2,
        label: this.$t('attendance.statusList.late'),
      },
      {
        key: 96,
        label: this.$t('attendance.statusList.excusedLate'),
      },
      {
        key: 3,
        label: this.$t('attendance.statusList.absent'),
      },
      {
        key: 4,
        label: this.$t('attendance.statusList.personal'),
      },
      !['dormitory', 'selfStudy'].includes(this.type) && {
        key: 48,
        label: this.$t('attendance.statusList.examAbsent'),
      },
      {
        key: 7,
        label: this.$t('attendance.statusList.illness'),
      },
      {
        key: 5,
        label: this.$t('attendance.statusList.holiday'),
      },
      {
        key: 41,
        label: this.$t('attendance.statusList.exams'),
      },
      {
        key: 42,
        label: this.$t('attendance.statusList.others'),
      },
    ].filter(item => item)
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get type(): any {
    return this.$route.params.reportType
  }

  private get reportData() {
    return this.reportDep[this.$route.params.reportType]
  }

  private queryNum: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(x => {
    return { key: x, label: x }
  })
  private reportDep: any = {
    semester: {
      reportId: {
        QA: 86,
        DEV: 86,
        UAT: 94,
        PRODUCTION: 34,
      },
      params: {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
        type: 'all',
        status: 2,
        classId: 'all',
        houseGroupId: 'all',
        sectionId: '',
        sectionIdList: [],
        studentName: '',
        studentNum: '',
      },
    },
    course: {
      reportId: {
        QA: 87,
        DEV: 87,
        UAT: 93,
        PRODUCTION: 33,
      },
      params: {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
        courseId: undefined,
        status: 2,
        studentName: '',
        studentNum: '',
        courseType: '1001',
        queryNum: '',
      },
    },
    abnormal: {
      reportId: {
        QA: 88,
        DEV: 88,
        UAT: 91,
        PRODUCTION: 31,
      },
      params: {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
        houseGroupId: 'all',
        sectionId: '',
        sectionIdList: [],
        studentName: '',
        studentNum: '',
      },
    },
    absent: {
      reportId: {
        QA: 89,
        DEV: 89,
        UAT: 92,
        PRODUCTION: 32,
      },
      params: {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
        type: 'all',
        teacherId: 'all',
        studentName: '',
        studentNum: '',
      },
    },
    cca: {
      reportId: {
        QA: 87,
        DEV: 87,
        UAT: 93,
        PRODUCTION: 33,
      },
      params: {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
        courseId: undefined,
        status: 2,
        studentName: '',
        studentNum: '',
        courseType: '1002',
      },
    },
    dormitory: {
      reportId: {
        QA: 161,
        DEV: 161,
        UAT: 178,
        PRODUCTION: 123,
      },
      params: {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
        floorId: 'all',
        status: 'all',
        studentName: '',
        studentNum: '',
        type: 'all',
      },
    },
    selfStudy: {
      reportId: {
        QA: 162,
        DEV: 162,
        UAT: 177,
        PRODUCTION: 124,
      },
      params: {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
        selfStudyRoomId: 'all',
        status: 'all',
        studentName: '',
        studentNum: '',
      },
    },
    subjectClass: {
      reportId: {
        QA: 214,
        DEV: 214,
        UAT: 213,
        PRODUCTION: 239,
      },
      params: {
        schoolYearId: undefined,
        sectionId: [],
        subjectId: [],
        courseId: [],
        teacherId: 'all',
        studentName: '',
        studentNum: '',
      },
    },
  }

  private get timeFilter(): any {
    return [this.reportData.params.start, this.reportData.params.end]
  }

  private set timeFilter(time) {
    this.reportDep[this.$route.params.reportType].params.start = time[0]
    this.reportDep[this.$route.params.reportType].params.end = time[1]
  }

  private formatParams(params): any {
    let formatParams = {} as any
    for (let key in params) {
      if (key === 'courseId' && this.$route.params.reportType !== 'cca') {
        formatParams[key] = params[key]?.[1] === 0 ? 0 : params[key]?.[1] || undefined
        continue
      }
      if (key === 'type' && this.$route.params.reportType === 'semester') {
        formatParams[key] = 10
        continue
      }
      if (key === 'studentNum' || key === 'studentName') {
        formatParams[key] = params[key]?.trim() || undefined
        continue
      }
      if (key === 'start' || key === 'end') {
        formatParams[key] = params[key]?.format('YYYY-MM-DD') || undefined
        continue
      }
      if (key === 'subjectId') {
        formatParams[key] = JSON.stringify(params[key])
        continue
      }
      if (key === 'sectionIdList') continue
      formatParams[key] =
        params[key] === 'all' ? undefined : params[key] === 0 ? 0 : params[key] || undefined
    }
    formatParams.schoolId = (getSchoolInfo() || {}).schoolId
    return formatParams
  }

  private getData(): any {
    this.filterCondition = this.formatParams(this.reportData.params)
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private reset(): void {
    this.reportDep = {
      semester: {
        reportId: {
          QA: 86,
          DEV: 86,
          UAT: 94,
          PRODUCTION: 34,
        },
        params: {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          type: 'all',
          status: 2,
          classId: 'all',
          houseGroupId: 'all',
          sectionId: '',
          sectionIdList: [],
          studentName: '',
          studentNum: '',
        },
      },
      course: {
        reportId: {
          QA: 87,
          DEV: 87,
          UAT: 93,
          PRODUCTION: 33,
        },
        params: {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          courseId: undefined,
          status: 2,
          studentName: '',
          studentNum: '',
          courseType: '1001',
          queryNum: '',
        },
      },
      abnormal: {
        reportId: {
          QA: 88,
          DEV: 88,
          UAT: 91,
          PRODUCTION: 31,
        },
        params: {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          houseId: 'all',
          sectionId: '',
          sectionIdList: [],
          studentName: '',
          studentNum: '',
        },
      },
      absent: {
        reportId: {
          QA: 89,
          DEV: 89,
          UAT: 92,
          PRODUCTION: 32,
        },
        params: {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          type: 'all',
          teacherId: 'all',
          studentName: '',
          studentNum: '',
        },
      },
      cca: {
        reportId: {
          QA: 87,
          DEV: 87,
          UAT: 93,
          PRODUCTION: 33,
        },
        params: {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          courseId: undefined,
          status: 2,
          studentName: '',
          studentNum: '',
          courseType: '1002',
        },
      },
      dormitory: {
        reportId: {
          QA: 161,
          DEV: 161,
          UAT: 94,
          PRODUCTION: 34,
        },
        params: {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          floorId: 'all',
          status: 'all',
          studentName: '',
          studentNum: '',
          type: 'all',
        },
      },
      selfStudy: {
        reportId: {
          QA: 162,
          DEV: 162,
          UAT: 94,
          PRODUCTION: 34,
        },
        params: {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          selfStudyRoomId: 'all',
          status: 'all',
          studentName: '',
          studentNum: '',
        },
      },
      subjectClass: {
        reportId: {
          QA: 214,
          DEV: 214,
          UAT: 213,
        },
        params: {
          schoolYearId: this.schoolYearList[0].key,
          sectionId: [],
          subjectId: [],
          courseId: [],
          teacherId: 'all',
          studentName: '',
          studentNum: '',
        },
      },
    }
    this.sectionIds = []
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    to.meta.routerName = 'reportType.' + to.params.reportType
    this.filterCondition = {
      show: false,
    }
    this.sectionIds = this.reportData.params.sectionIdList
    this.getDropDownInfo()
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getSectionCascade(),
      DropDownController.getHouseGroupListAll(),
      DropDownController.getClassList(),
      CourseController.getCoursesOptionsCascade(),
      TeacherController.getListAll(),
      DropDownController.getFloorList(),
      DropDownController.getSsRoomList(),
      DropDownController.getSchoolYearList(),
      DropDownController.getSubjectListForAttendance(),
    ])
      .then(res => {
        this.grades = res[0].data
        this.colleges = res[1].data
        this.classes = res[2].data
        this.courses = res[3].data.map(item => {
          return {
            ...item,
            subOptions: item.subOptions.map(it => {
              return {
                key: it.key,
                value: it.value,
                enValue: it.enValue,
              }
            }),
          }
        })
        this.teachers = res[4].data.map(item => {
          ;(item.teacherId as any) = item.teacherId.toString()
          return item
        })
        this.floorList = res[5].data
        this.studyRoomList = res[6].data
        this.schoolYearList = res[7].data
        this.reportDep.subjectClass.params.schoolYearId = res[7].data[0].key
        this.subjectList = res[8].data
        if (this.$route.params.reportType !== 'cca') {
          this.getData()
        }
      })
      .catch(err => {
        console.error(err)
      })
    if (this.$route.params.reportType === 'cca') {
      DropDownController.getCcaCourse()
        .then(res => {
          this.ccaClasses = res.data
          this.getData()
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  private onGradeChange(value, label, extra) {
    const isVNode = judgeVnode(extra.triggerNode)
    const sectionId = (extra.allCheckedNodes || [])
      .map(item => {
        if (!isVNode) {
          if (item.children) {
            return item.children.map(item => item.node.key)
          } else {
            return item.node.key
          }
        } else {
          if (item.componentOptions.children.length) {
            return item.componentOptions.children.map(item => item.data.key)
          } else {
            return item.data.key
          }
        }
      })
      .flat()
      .toString()
    this.reportData.params.sectionId = sectionId ? `[${sectionId}]` : undefined
    this.$set(this.reportData.params, 'sectionIdList', value)
  }

  private transCols(cols: any[], data: any[]): any[] {
    let mtCol = cols.find(col => col.title === 'Modify Time')
    let tCol = cols.find(col => col.title === 'Teachers')
    let dIndex = cols.find(col => col.title === 'Date')?.dataIndex
    if (mtCol) {
      this.$set(mtCol, 'customRender', (value, row) => {
        const current: any = dIndex
          ? moment(row[dIndex], 'YYYY/MM/DD HH:mm').endOf('day')
          : undefined
        return this.$createElement(
          'div',
          {
            style: {
              color: current && new Date(value).valueOf() > current?.valueOf() ? 'red' : 'inherit',
            },
          },
          [value]
        )
      })
    }
    if (tCol) {
      this.$set(tCol, 'ellipsis', true)
      this.$set(tCol, 'customRender', (value, row) => {
        return this.$createElement(
          FlexTooltip,
          {
            props: {
              value: value,
            },
          },
          [value]
        )
      })
    }
    return [...cols]
  }
}
