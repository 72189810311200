import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { i18n } from '@/i18n/i18n'
import jwt from 'jsonwebtoken'
import { saveAs } from 'file-saver'
import moment from 'moment'

const fullDomain = process.env.VUE_APP_DOMAIN
const exportUrl = process.env.VUE_APP_METABASE_EXPORT

@Component({
  components: {},
})
export class Metabase extends Vue {
  @Prop()
  public params!: any
  @Prop()
  public question!: number
  @Prop()
  public hideParams!: string
  @Prop()
  public transCols!: (cols: any[], data: any[]) => any[]
  @Prop()
  public transData!: (data: any[]) => any[]
  @Prop()
  public showExport!: any
  @Prop()
  public type!: any
  // @Prop({
  //   default: 15,
  // })
  // public size!: number;
  public cols: any[] = []
  public list: any[] = []
  public info: any = {}
  public loading1 = false
  public loading2 = false
  public btnLoading = false
  public current = 1
  public size = 30
  public get token() {
    if (this.params.show === false) {
      return ''
    }
    const payload = {
      resource: {
        question: Number(this.question),
      },
      params: this.params,
      exp: Math.round(Date.now() / 1000) + 4 * 60 * 60,
    }
    console.log(payload)
    return jwt.sign(payload, process.env.VUE_APP_METABASE_KEY as string)
  }
  public get iframeUrl() {
    return (
      process.env.VUE_APP_METABASE_URL +
      '/embed/question/' +
      this.token +
      '#bordered=false&titled=false&hide_parameters=' +
      this.hideParams
    )
  }

  public created() {
    this.getList(this.token)
    this.getInfo(this.token)
  }
  @Watch('token')
  public tokenChange(t: string) {
    this.getList(t)
    this.getInfo(t)
    this.current = 1
  }
  public getInfo(token: string) {
    if (!token) {
      return
    }
    this.loading1 = true
    axios
      .get(`${fullDomain}metabase/api/embed/card/${this.token}`)
      .then(res => {
        if (res && res.data) {
          this.info = res.data || {}
        }
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading1 = false))
  }
  public getList(token: string) {
    if (!token) {
      return
    }
    this.loading2 = true
    this.list = []
    axios
      .get(`${fullDomain}metabase/api/embed/card/${this.token}/query`)
      .then(res => {
        if (res && res.data && res.data.data) {
          const data = res.data.data
          if (this.transData) {
            this.list = this.transData(data.rows)
          } else {
            const list = data.rows.map((d: any[]) => {
              const out: any = {}
              d.map((l, i) => {
                out[i] = l
              })
              out.key = d[0]
              return out
            })
            this.list = list
          }
          const cols = data.cols.map((col: any, i: number) => {
            let sorter
            if (col.base_type !== 'type/Text') {
              sorter = (a: any, b: any) => a[i] - b[i]
            }
            return {
              title: col.display_name,
              dataIndex: i + '',
              sorter,
              align: 'left',
            }
          })
          if (this.transCols) {
            this.cols = this.transCols(cols, this.list)
          } else {
            this.cols = cols
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading2 = false))
  }
  public render() {
    return (
      <a-spin spinning={!this.token || this.loading1 || this.loading2}>
        {this.info.display === 'table' ? (
          <div>
            {(this.showExport === '' || this.showExport) && (
              <a-button
                loading={this.btnLoading}
                type="primary"
                style="float: left; margin-bottom: 12px;z-index:1"
                onClick={() => {
                  this.btnLoading = true
                  fetch(`${fullDomain}metabase/api/embed/card/${this.token}/query/xlsx`)
                    .then(async res => {
                      const blob = await res.blob()
                      const { start, end } = this.params
                      saveAs(
                        blob,
                        `${this.$t(`router.reportType.${this.type}`)}_${moment(start).format(
                          'YYYYMMDD'
                        )}-${moment(end).format('YYYYMMDD')}.xlsx`
                      )
                    })
                    .finally(() => (this.btnLoading = false))
                }}>
                {this.$t('common.export')}
              </a-button>
            )}
            <a-table
              size="large"
              columns={this.cols}
              dataSource={this.list}
              pagination={{
                pageSize: this.size,
                current: this.current,
                onChange: (page: number, size) => {
                  this.current = page
                  this.size = size
                },
                showTotal: total => i18n.t('common.pageTotal', { total }),
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50'],
              }}
              defaultExpandAllRows={true}
              {...{ props: { ...this.$attrs } }}
            />
          </div>
        ) : this.token ? (
          <iframe
            src={this.iframeUrl}
            frameborder="0"
            allowtransparency
            style="width: 100%; height: 400px;"
          />
        ) : (
          <div style="width: 100%; height: 300px;"></div>
        )}
      </a-spin>
    )
  }
}
