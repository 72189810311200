

















import { Component, Vue, Watch } from 'vue-property-decorator'
import reportViewer from './components/ReportViewer.vue'

@Component({
  components: {
    reportViewer,
  },
})
export default class AttendanceReport extends Vue {
  private get menuList(): Array<any> {
    return [
      {
        key: 'abnormal',
        label: this.$t('router.reportType.abnormal'),
      },
      {
        key: 'semester',
        label: this.$t('router.reportType.semester'),
      },
      {
        key: 'course',
        label: this.$t('router.reportType.course'),
      },
      {
        key: 'subjectClass',
        label: this.$t('router.reportType.subjectClass'),
      },
      {
        key: 'absent',
        label: this.$t('router.reportType.absent'),
      },
      {
        key: 'cca',
        label: this.$t('router.reportType.cca'),
      },
      {
        key: 'dormitory',
        label: this.$t('router.reportType.dormitory'),
      },
      {
        key: 'selfStudy',
        label: this.$t('router.reportType.selfStudy'),
      },
    ]
  }

  private get type(): any {
    return this.$route.params.reportType
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'attendanceReport') {
      if (!to.params.reportType) {
        this.$router
          .replace({ name: 'attendanceReport', params: { reportType: 'abnormal' } })
          .catch(err => {})
      }
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.replace({ name: 'attendanceReport', params: { reportType: key } }).catch(err => {})
  }
}
