


































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import filterGroup from '@/components/filterGroup'
import { createPagination } from '@/constant/constant'
import { i18n } from '@/i18n/i18n'
import { createMergeArray, getSchoolInfo, getToken, guid, judgeVnode, toPage } from '@/utils/utils'
import moment from 'moment'
import {
  CCACurriculumController,
  DropDownController,
  TeacherController,
} from '@/services/request.service'
import { Debounce } from '@/utils/utils'
import FlexTooltip from '@/components/FlexTooltip.vue'
import TableContainer from './TableContainer.vue'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import saveAs from '@/utils/FileSaver'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
    TableContainer,
  },
})
export default class LifeBlockReport extends Vue {
  private ccaClasses: any = []
  private classes: any = []
  private classrooms: any = []
  private colleges: Array<any> = []
  private data: any = []
  private exportLoading: any = false
  private filter: any = {
    date: [moment().startOf('day'), moment().endOf('day')],
    classroomIds: [],
    studentName: '',
    studentNum: '',
    dayOfWeeks: [],
    sectionIds: [],
    classIds: [],
    houseGroupIds: [],
    teacherIds: [],
    courseIds: [],
    week: moment().startOf('week'),
  }
  private grades: any = []
  private loading: any = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private studentData: any = []
  private teachers: any = []

  private get classesOfGrade(): any {
    return this.classes.map(item => item.subOptions).flat(1)
  }
  private get columns(): any {
    return [
      {
        dataIndex: 'studentName',
        title: this.$t('common.student'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 120,
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'sectionName',
        title: this.$t('common.grade'),
        width: 80,
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'houseName',
        title: this.$t('common.advisory'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'classTime',
        title: this.$t('common.date'),
        width: 120,
        ellipsis: true,
        scopedSlots: { customRender: 'date' },
      },
      {
        dataIndex: 'dayOfWeek',
        title: this.$t('LBReport.schoolDay'),
        width: 100,
        scopedSlots: { customRender: 'schoolDay' },
      },
      {
        dataIndex: 'courseName',
        title: this.$t('common.CCAClass'),
        ellipsis: true,
        scopedSlots: { customRender: 'specialFlex' },
      },
      {
        dataIndex: 'teacherNames',
        title: this.$t('common.teacher'),
        ellipsis: true,
        scopedSlots: { customRender: 'teachers' },
      },
      {
        dataIndex: 'classRoomName',
        title: this.$t('common.classroom'),
        ellipsis: true,
        scopedSlots: { customRender: 'specialFlex' },
      },
    ]
  }
  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 30,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 30,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 30,
      },
      {
        dataIndex: 'zhName',
        title: this.$t('common.cnName'),
        width: 30,
      },
      ...this.columns.map(item => ({
        dataIndex: item.dataIndex,
        title: item.title,
        width: 30,
      })),
    ]
  }

  private get formatSections(): any {
    return this.grades.map(item => {
      return {
        children: item.subOptions.map(child => ({
          key: child.key,
          title: this.locale === 'zh' ? child.value : child.enValue || child.value,
          value: child.key,
        })),
        title: `${item.value}-${item.enValue}`,
        key: `${item.enValue}_${item.extraValue}_${item.key}`,
        value: `${item.enValue}_${item.extraValue}_${item.key}`,
      }
    })
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private get menuList(): Array<any> {
    return [
      {
        key: 'course',
        label: this.$t('LBReport.courseForm'),
      },
      {
        key: 'week',
        label: this.$t('LBReport.weekForm'),
      },
    ]
  }
  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }
  private get weeks(): Array<any> {
    return [
      {
        label: this.$t('weekday.1'),
        value: 1,
      },
      {
        label: this.$t('weekday.2'),
        value: 2,
      },
      {
        label: this.$t('weekday.3'),
        value: 3,
      },
      {
        label: this.$t('weekday.4'),
        value: 4,
      },
      {
        label: this.$t('weekday.5'),
        value: 5,
      },
      {
        label: this.$t('weekday.6'),
        value: 6,
      },
      {
        label: this.$t('weekday.7'),
        value: 7,
      },
    ]
  }
  private get LBRType(): any {
    return this.$route.params.LBRType
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'lifeBlockReport', params: { LBRType: key } }).catch(err => {})
  }

  private async exportStudents(): Promise<any> {
    this.exportLoading = true
    let { date, week, ...rest } = this.filter
    const condition = {
      ...rest,
      pageCurrent: 1,
      pageSize: 0,
      startTime: moment(date[0])
        .startOf('day')
        .valueOf(),
      endTime: moment(date[1])
        .endOf('day')
        .valueOf(),
    }
    CCACurriculumController.reportList(condition)
      .then(async res => {
        const students = res.data.items.map(student => ({
          ...student,
          classTime: moment(student.classTime).format('YYYY/MM/DD'),
          teacherNames: student.teacherNames.join('、'),
          dayOfWeek: this.$t(`shortWeekday.${student.dayOfWeek}`),
        }))
        const file = exportXlsx(this.excelColumns, students)
        const ws = file.getWorksheet(1)

        file
          .getWorksheet(1)
          .getRow(1)
          .eachCell(cell => {
            cell.fill = {
              type: 'pattern',
              pattern: 'darkTrellis',
              fgColor: { argb: 'FFE9E9E9' },
              bgColor: { argb: 'FFE9E9E9' },
            } as any
          })
        ws.eachRow(row => {
          row.height = 30
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })
        let dateStr = `${date[0].format('YYYYMMDD')}_${date[1].format('YYYYMMDD')}`
        await writeFile(file, `${this.$t('LBReport.xlsxName', { date: dateStr })}.xlsx`)
        this.exportLoading = false
      })
      .catch(err => console.log(err))
  }

  private exportStudentTable(): void {
    this.exportLoading = true
    setTimeout(() => {
      this.exportLoading = false
    }, 3000)
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let { week, ...rest } = this.filter
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportWeekTable/${token}/${
      schoolInfo.schoolId
    }/${week.valueOf()}/${rest.classIds.toString() || null}/${rest.houseGroupIds.toString() ||
      null}/${rest.studentName || null}/${rest.studentNum || null}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.landscape=true&pdf.printBackground=true&pdf.scale=0.52&pdf.margin.top=4.5mm&pdf.margin.left=3.5mm&pdf.margin.right=4mm`

    let date = `${moment(week).format('YYYYMMDD')}_${moment(week)
      .add(7, 'day')
      .format('YYYYMMDD')}`
    setTimeout(() => {
      const fileName = `${this.$t('LBReport.tableName', { date })}.pdf`
      saveAs(url, fileName)
    }, 0)
  }

  @Debounce(500)
  private fetchData(): any {
    this.getData()
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.loading = true
    this.data = []
    this.studentData = []
    let { date, week, ...rest } = this.filter
    const condition = {
      ...rest,
      pageCurrent: page.current,
      pageSize: page.pageSize,
      startTime:
        this.LBRType === 'course'
          ? moment(date[0])
              .startOf('day')
              .valueOf()
          : moment(week)
              .startOf('day')
              .valueOf(),
      endTime:
        this.LBRType === 'course'
          ? moment(date[1])
              .endOf('day')
              .valueOf()
          : moment(week)
              .add(7, 'day')
              .endOf('day')
              .valueOf(),
    }
    if (this.LBRType === 'course') {
      CCACurriculumController.reportList(condition)
        .then(res => {
          this.data = res.data.items

          this.pagination.total = res.data.totalItem
          this.pagination.current = page.current
          toPage(page, this.data, page => {
            this.pagination.current = page
            this.refresh()
          })
        })
        .catch(err => console.log(err))
        .finally(() => (this.loading = false))
    } else {
      if (!condition.classIds.length && !condition.houseGroupIds.length) {
        this.loading = false
        return
      }
      condition.pageSize = 0
      condition.pageCurrent = 1
      CCACurriculumController.getClassArrangesByStudentAndWeek(condition)
        .then(res => {
          this.studentData = res.data.items

          this.pagination.total = res.data.totalItem
          this.pagination.current = page.current
          toPage(page, this.data, page => {
            this.pagination.current = page
            this.refresh()
          })
        })
        .catch(err => console.log(err))
        .finally(() => (this.loading = false))
    }
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getClassList(),
      DropDownController.getCcaCourse(),
      DropDownController.getHouseGroupListAll(),
      TeacherController.getListAll(),
      DropDownController.getClassRoomCascade(),
      DropDownController.getSectionCascade(),
    ])
      .then(res => {
        this.classes = res[0].data
        this.ccaClasses = res[1].data
        this.colleges = res[2].data
        this.teachers = res[3].data
        let classrooms = []
        res[4].data.forEach(item => {
          createMergeArray(item, classrooms)
        })
        this.classrooms = classrooms
        this.grades = res[5].data
        if (this.LBRType === 'week') {
          this.$nextTick(() => {
            this.filter.classIds = [this.classesOfGrade[0].key]
            this.getData()
          })
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private onGradeChange(value, label, extra) {
    const isVNode = judgeVnode(extra.triggerNode)
    this.filter.sectionIds = (extra.allCheckedNodes || [])
      .map(item => {
        if (!isVNode) {
          if (item.children) {
            return item.children.map(item => item.node.key)
          } else {
            return item.node.key
          }
        } else {
          if (item.componentOptions.children.length) {
            return item.componentOptions.children.map(item => item.data.key)
          } else {
            return item.data.key
          }
        }
      })
      .flat()
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private reset(): void {
    this.filter = {
      date: [moment().startOf('day'), moment().endOf('day')],
      classroomIds: [],
      studentName: '',
      studentNum: '',
      dayOfWeeks: [],
      sectionIds: [],
      classIds:
        this.LBRType === 'week' && this.classesOfGrade[0] ? [this.classesOfGrade[0].key] : [],
      houseGroupIds: [],
      teacherIds: [],
      courseIds: [],
      week: moment().startOf('week'),
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'lifeBlockReport') {
      if (!to.params.LBRType) {
        this.$router
          .replace({ name: 'lifeBlockReport', params: { LBRType: 'course' } })
          .catch(err => {})
      } else {
        this.filter = {
          date: [moment().startOf('day'), moment().endOf('day')],
          classroomIds: [],
          studentName: '',
          studentNum: '',
          dayOfWeeks: [],
          sectionIds: [],
          classIds: [],
          houseGroupIds: [],
          teacherIds: [],
          courseIds: [],
          week: moment().startOf('week'),
        }
        this.getDropDownInfo()
        if (to.params.LBRType === 'course') {
          this.getData()
        }
      }
    }
  }
}
