














import { Component, Vue } from 'vue-property-decorator'
import { getSchoolInfo } from '@/utils/utils'

@Component
export default class DataForm extends Vue {
  private schoolId = (getSchoolInfo() || {}).schoolId

  private get showChild(): boolean {
    return this.$route.name !== 'dataForm'
  }
  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get items(): Array<any> {
    const items = [
      {
        id: 1,
        label: this.$t('router.attendanceReport'),
        value: 'attendanceReport',
        icon: 'line-chart',
        color: '#40A9FF',
        auth: '2046',
      },
      {
        id: 2,
        label: this.$t('router.lifeBlockReport'),
        value: 'lifeBlockReport',
        icon: 'line-chart',
        color: '#36CFC9',
        auth: '2091',
      },
      {
        id: 3,
        label: this.$t('router.eGradeReport'),
        value: 'eGradeReport',
        icon: 'line-chart',
        color: '#FFBF00',
        auth: '2116',
      },
      {
        id: 4,
        label: this.$t('router.gradeReport'),
        value: 'gradeReport',
        icon: 'line-chart',
        color: '#FFBF00',
        auth: '2124',
      },
    ]
    return items.filter(item => this.operationAuths.includes(item.auth))
  }

  private toPage(value): void {
    this.$router.push({ name: value }).catch(err => {})
  }
}
